import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation, Trans } from "react-i18next";
import SocketContext from '../../Utils/socket-context';
import FontAwesome from 'react-fontawesome';
import { faBell, faChevronCircleDown, faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../../logo_256.png'
import logo2 from '../../../logo2.PNG'
import logo_header from '../../../logo_header.PNG'



class CommonTopMenu extends Component {
	constructor(props) {
		super(props);

		

		this.state = {
			menuOpen: false
		}


		this.menuRef = React.createRef();
		this.blur = this.blur.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);

		// WTNConnect();
		// WTNotifications.connect();


	}

	setReadNotifications() {

	}

	blur() {
		this.setState({menuOpen: false});
	}


	toggleMenu() {
		this.setState({menuOpen: !this.state.menuOpen});
		
	}


	componentDidUpdate(prev) {
		const that = this;

		if(this.props.socket && !prev.socket) {
			this.props.socket.on('test', function (data) {
				
				if(data.type && data.type === 'user_update')
					console.log('update user state')
			});
		}

	}

	componentDidMount() {
		const that = this;

		if(this.props.socket) {

			this.props.socket.on('test', function (data) {
				
				if(data.type && data.type === 'user_update')
					console.log('update user state')

			});
		}

	}

	render() {
		
		const menuOpen = this.state.menuOpen;
		
		return (
			<div className={"topbar-wrap"} >


				<div className={"row nav nav-tabs"} >
				<img src={logo_header} alt="logo"/>	
					{this.props.auth ? 
						(this.props.user.role !== "client" && this.props.user.role !== "finance" ) && this.props.user.role !== "gestion" ? <ul className="header-menu" >					
              {this.props.user.role !== "commercial" && this.props.user.role !== "sup ops" && this.props.user.role !== "superviseur" && 
            this.props.user.role !== "gestion" && <li><NavLink strict to="/manager/dashboard"><Trans i18nKey="menu_dashboard" /> </NavLink></li>}
							<li><NavLink to="/bordereau">Bordereau</NavLink></li>
							{/* <li><NavLink to="/manager/agents"><Trans i18nKey="label_agents" /> </NavLink></li>	 */}
							<li><NavLink to="/client">Clients </NavLink></li>
							<li><NavLink to="/factures">Factures </NavLink></li>
							<li><NavLink to="/collect">Collecte </NavLink></li>
              { (this.props.user.role == "admin" || this.props.user.role == "manager") && <li><NavLink to="/debts">Dettes </NavLink></li>}
							{this.props.user.role == "manager"  &&<li><NavLink to="/tarification">Tarification </NavLink></li>}
							<li >
								<div className={"nav-item dropdown"}>
									<a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Users</a>

									<div class="dropdown-menu " >
										{/* <a class="dropdown-item text-dark"  href="/users/managers">Managers</a> */}
										<a class="dropdown-item text-dark" href="/users/agents">Coursier</a>
										{this.props.user.role !== "sup ops" && this.props.user.role !== "superviseur" && this.props.user.role !== "commercial"  && <a class="dropdown-item text-dark" href="/users/commercial">Customer service</a>}
									</div>
									
								</div>
								
							</li>
							{/* <li><NavLink to="/account">Compte </NavLink></li>	 */}
							{/* {this.props.user.role === 'admin' && (<li><NavLink to="/payments"><Trans i18nKey="label_payment" /> </NavLink></li>)}		
							<li><NavLink to="/manager/team">Teams </NavLink></li>
							<li><NavLink to="/settings">Settings </NavLink></li>  */}
						</ul>
						:
						this.props.user.role === "client" ? <ul className="header-menu">
							<li><NavLink to="/dashboard"><Trans i18nKey='menu_dashboard' /> </NavLink></li>					
							<li><NavLink to="/accueil">Accueil </NavLink></li>
							<li><NavLink to="/commande">Mes Commandes </NavLink></li>
							<li><NavLink to="/mesfactures">Mes Factures </NavLink></li>
							<li><NavLink to="/mescollectes">Mes Collectes </NavLink></li>
						</ul>
						: this.props.user.role === "commercial" ?
						<ul className="header-menu">					
							<li><NavLink to="/accueil">Accueil </NavLink></li>
							<li><NavLink to="/commande">Commandes </NavLink></li>
							<li><NavLink to="/mesfactures">Factures </NavLink></li>
            </ul>
            : this.props.user.role === "finance" ?
            <ul className="header-menu">					
              {/* <li><NavLink to="/accueil">Accueil</NavLink></li> */}
              <li><NavLink to="/bordereau">Bordereau </NavLink></li>
              <li><NavLink to="/mesfactures">Factures </NavLink></li>
            </ul>
            : this.props.user.role === "gestion" ?
            <ul className="header-menu">					
              {/* <li><NavLink to="/accueil">Accueil</NavLink></li> */}
              <li><NavLink to="/bordereau">Bordereau </NavLink></li>
							<li><NavLink to="/factures">Factures </NavLink></li>
							<li><NavLink to="/client">Clients </NavLink></li>
            </ul>



            // : this.props.user.role === "comptable" ?
            // <ul className="header-menu">					
            //   {/* <li><NavLink to="/accueil">Accueil</NavLink></li> */}
            //   <li><NavLink to="/bordereau">Bordereau </NavLink></li>
            //   <li><NavLink to="/mesfactures">Factures </NavLink></li>
            // </ul>

						: <ul className="header-menu">					
							<li><NavLink to="/collect">Collect </NavLink></li>
						</ul>
					:
					<ul className="header-menu">					
							<li><NavLink  to="/login">Login</NavLink></li>
							
						</ul>
					}

					{/* <input type="text" placeholder={"Search for tasks, clients, users..."} className={"top-search"}  />
					<button type="button" className={"search-btn"} >
						<FontAwesomeIcon icon={faSearch} />
					</button> */}
				</div>

				<div className={"user-profile"} >
					<div className={"user-notifications"}>
						<span className={"user-profile-notification"}>
						{this.props.notifications.filter(x => !x.read).length} <FontAwesomeIcon icon={faBell} />
						<ul className={"notification-list"} onMouseEnter={this.props.setReadNotifications}>

							{this.props.notifications.length > 0 ? this.props.notifications.map(x => (

								<li key={x.id} className={x.read ? "" : "active"}>
								{/* <Link to={{
									pathname:'/task/'+x.content.match(/\d+/g),
								}}>
									<span className={"notification-text"}>{x.content}</span>
									<span className={"notification-time"}>{x.datetime}</span>
								</Link> */}
								<Link to={{
									pathname:'/',
								}}>
									<span className={"notification-text"}>{x.content}</span>
									<span className={"notification-time"}>{x.datetime}</span>
								</Link>
								</li>

							)) : "<li className={'notification-text'}>Aucune notification</li>" }

						</ul>

						</span>
					</div>

					<div className={"user-avatar user-profile-avatar"}>
						<img alt="Profile picture" src={this.props.user.picture ? "/img/"+this.props.user.picture : "/img/profile.jpg"} />
					</div>

					<div className={"user-info"}>
						<span className={"user-info-username"}>{this.props.user.username}</span>
						<span className={"user-info-role"}>
							{!!this.props.user.role && this.props.user.role != 'commercial' && this.props.user.role != 'agent' ? 
								this.props.user.role.toUpperCase()
								:
								!!this.props.user.role && this.props.user.role == 'commercial' && 'CUSTOMER SERVICE'
								}
								{!!this.props.user.role && this.props.user.role == 'agent' && 'COURSIER'}</span>
					</div>

					<div className={"user-menu"}>
						<span onClick={this.toggleMenu}  className={"toggle-menu-btn"}><FontAwesomeIcon icon={faChevronDown} /></span>
						
						<ul  className={"user-profile-menu "+ (menuOpen === true && "open")} ref={this.menuRef} onBlur={this.blur}>
							<li><Link to="/account"><Trans i18nKey="my_account" /></Link></li>
							<li><Link to="/account/security"><Trans i18nKey="change_my_password" /></Link></li>
							<li><Link to="/account/myapp"><Trans i18nKey="label_app" /></Link></li>

							<li><a href="javascript:void(0)"  onClick={e => this.props.logOut(false)} ><Trans i18nKey="logout" /></a></li>
							<li className={"sep"}></li>
							<li><Link to="/"><Trans i18nKey="help" /></Link></li>

						</ul>
						
					</div>


				</div>

				<div className={"clear"}></div>
			</div>
		)
	}
}
const mapState = (state) => {
	return {
		auth: state.auth,
		user: state.user
	};
  };

const CommonTopMenuIO = props => (
	<SocketContext.Consumer>
		{socket => <CommonTopMenu {...props} socket={socket} />}
	</SocketContext.Consumer>
  )

export default connect(null, null, null, { pure: false},mapState)(CommonTopMenuIO)
