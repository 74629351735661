import React from "react";
import api from "../../Utils/Api";
import { connect } from "react-redux";
import { startLoading, doneLoading } from "../../Redux/actions";
import { closeFlash, setFlash } from './../../Redux/actions/index';
import DatePicker from "react-datepicker";
import Modal from 'react-bootstrap/Modal';

class DebtsList extends React.Component {
  constructor(props) {
    super(props);
    
   
    this.state = {
      debtsLoading: true,
      debts: [],
      showAlert: false,
      selectedDate: new Date(),
      selectedMonth: new Date().getMonth() + 1,
      selectedYear: new Date().getFullYear(),
      isModalOpen: false,
      paymentDetails: {},
      warning: ''
    };
  }

  loadDebts = () => {
    api.post(`/api/debts/month/`+this.state.selectedMonth+'/year/'+this.state.selectedYear+'/track').then(res => {
      console.log(res);
      if (res.data.success){
        this.setState({
          debtsLoading: false,
          debts: res.data.debts
        });
      }
    });
  };

  
  // handleDateChange = (e) => {
  //   this.setState({selectedDate : e});
  //   const selectedYear = e.getFullYear();
  //   const selectedMonth = e.getMonth() + 1;
  //   this.setState({ selectedMonth : selectedMonth });
  //   this.setState({ selectedYear : selectedYear });
  //   this.loadDebts();
  // }
  //

  handleDateChange = (e) => {
    this.setState({ selectedDate: e }, () => {
      const selectedYear = e.getFullYear();
      const selectedMonth = e.getMonth() + 1;
      this.setState({ selectedMonth: selectedMonth, selectedYear: selectedYear }, () => {
        this.loadDebts();
      });
    });
  };

  
  componentDidMount() {
    this.loadDebts();
  }

    // payDebt = (crediteur,debiteur,somme) => {
    // const data = {
    //   "crediteur":crediteur,
    //   "debiteur":debiteur,
    //   "somme":somme
    // };

    //   api.post(`/api/debt/month/`+this.state.selectedMonth+'/year/'+this.state.selectedYear+'/pay',data).then(res => {
    //   console.log(res);
    //   this.setState({
    //     debtsLoading: false,
    //   });
    //   this.loadDebts();
    // });
  // }
  //

  payDebt = () => {
    const { crediteur, debiteur, somme } = this.state.paymentDetails;
    const data = {
      "crediteur": crediteur,
      "debiteur": debiteur,
      "somme": somme
    };

    api.post(`/api/debt/month/`+this.state.selectedMonth+'/year/'+this.state.selectedYear+'/pay', data).then(res => {
      console.log(res);
      if (res.data.success){
        this.setState({
          debtsLoading: false,
          isModalOpen: false
        });
        this.loadDebts();
      }else{
        console.log(res.data.error);
        this.setState({message:res.data.error,
          alertClass: "alert alert-warning d-block"
          })
      }
    });
  }


  openModal = (crediteur, debiteur, somme) => {
    this.setState({
      isModalOpen: true,
      paymentDetails: { crediteur, debiteur, somme }
    });
  }

  closeModal = () => {
    this.setState({ isModalOpen: false });
  }


  render() {

   return (
      <>
        <React.Fragment>
				  {Boolean(this.state.message) && <span className={this.state.alertClass}>{this.state.message}</span>}
          <div className={"container-header"}>
            Dettes
          </div>
          <DatePicker
            className="custom-date-picker"
            selected={this.state.selectedDate}
            onChange={this.handleDateChange}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            showFullMonthYearPicker
            showFourColumnMonthYearPicker
          />


        <div className="ctable mb-3  ui-rect np">
          <div className={"row head-titles py-2 mx-2"} style={{ background: 'white' ,  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'}}>
              <div className={"col-md-2"}>crediteur</div>
              <div className={"col-md-2"}>debiteur</div>
              <div className={"col-md-3"}>somme</div>
              <div className={"col-md-4 text-right"}>Actions</div>
            </div>

            {this.state.debts.map((debt, idx) => (
            <div key={idx} className={"row ctable-row"}>
              <div className={"col-md-2"}>{debt.crediteur}</div>
              <div className={"col-md-2"}>{debt.debiteur}</div>
              <div className={"col-md-3"}>{debt.somme} DA</div>
              
              <div className={"col-md-4 action-col"}>
                 <button className={"btn btn-sm btn-secondary ml-2 p-2"}
                    onClick={() => this.openModal(debt.crediteur, debt.debiteur, debt.somme)}
                >
                  Marquer comme payé
                </button>
              </div>
            </div>
          ))}
        </div>

        <Modal
            show={this.state.isModalOpen}
            onHide={this.closeModal}
            centered={true}
          >
            <Modal.Header>
              <Modal.Title>Confirmation de paiement </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Etes-vous sûr que les {this.state.paymentDetails.somme} de dettes de {this.state.paymentDetails.debiteur} envers {this.state.paymentDetails.crediteur} ont été payées ?
            </Modal.Body>
            <Modal.Footer>
              <button
                className={"btn"}
                onClick={this.closeModal}
              >
                Annuler
              </button>
              <button
                onClick={this.payDebt}
                className={"btn btn-primary"}
              >
                OK
              </button>
            </Modal.Footer>
          </Modal>

        </React.Fragment>
      </>
    );
  }
}

const mapDispatch = dispatch => {
  return {
    isLoading: m => dispatch(startLoading(m)),
    doneLoading: m => dispatch(doneLoading()),
    setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f))
  };
};
export default connect(null, mapDispatch)(DebtsList);
