import React from "react";
import api from "../../Utils/Api";
import { jsonFromRef } from "../../Utils/Helpers";
import { connect } from "react-redux";
import { startLoading, doneLoading, setAuthAction } from "../../Redux/actions";
import _ from 'lodash';
import { Trans } from "react-i18next";
import { faCheck, faTimes,  faCommentAlt,  faTag, faList, faChevronRight, faFileInvoiceDollar, faUser, faTable, faUserTag } from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as helpers from '../../Utils/Helpers';
import Select from "react-select";
import { closeFlash, setFlash } from './../../Redux/actions/index';




class ClientNew extends React.Component {


  constructor(props) {
    super(props);
    this.func = _.debounce(this.handleInputChangeF.bind(this), 250);
    this.handleInputChange = e => { 
      e.persist();
      this.func(e);
      // Handle checkbox inputs
      if (e.target.type === "checkbox") {
        console.log(e.target.checked);
        this.setState((prevState) => ({
          [e.target.name]: e.target.checked, // Use the checked value directly
        }));
      } 
    }

    this.checkUsername = _.debounce(this.checkUsername.bind(this), 250);
    this.checkEmail = _.debounce(this.checkEmail.bind(this), 250);

    this.state = {
      retour : false,
      commission_collect : false,
      error: "",
      app_id: "sodMSfpEnNEZfHC7oV0M",
      app_code: "noVfKVAMsfkjYV3Cv6jmnA",
      useHTTPS: true,
      center: {
        lat: 36.75,
        lng: 3.04,
      },
      zoom: 5,
      address: "",
      last_use: "component",
      form: {
        username: "",
        password: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
				station: ""
      },
      validUsername: true,
      validEmail: true,
      processingUsername: false,
      processingEmail: false,
      typeClientSelected: 'Client Corporate',
			stations: [],
      typesFacture: [] //if {} it will trow an error
    }

  }

  // updateRetour = (e) => {
  //   this.setState({retour: e.target.value});
  // }
  
  // updateCommissionCollect = (e) => {
  //   this.setState({commission_collect : e.target.value});
  // }


    componentDidMount() {
        this.platform = new window.H.service.Platform(this.state);
		var layer = this.platform.createDefaultLayers();
		var container = document.querySelector('#here-maps');
	
		this.map = new window.H.Map(container, layer.normal.map, {
			center: this.state.center,
			zoom: this.state.zoom,
		});
		
		var events = new window.H.mapevents.MapEvents(this.map);
		new window.H.mapevents.Behavior(events);
        new window.H.ui.UI.createDefault(this.map, layer)

        let that = this 
        api.get('/api/form/typefacturation').then(result => {
            if(result.data.success){
                that.setState({
                    typesFacture:result.data.types
                })
            }
        })

        api.get('/api/form/station').then(result => {
            if(result.data.success){
                that.setState({
                    stations:result.data.stations
                })
            }
        })

        
    }

    submitNewClient = (e) => {
        e.preventDefault();

        

        const formData = new FormData(this.clientForm);
        let data = {};
        for (var [key, value] of formData.entries()) data[key] = value;

        

        if(!data['username'] || !data['email'] || !data['password'] || !data['client_address'] || !data['client_phone'] || !data['facturation_type'] ) {
            this.setState({error: "Veuillez remplir les champs obligatoire"})
            return false;
        }

        // testing format
        //let format = /^([0-9][0-9][\/\\][-][0-9]{10,10} [A-Z] [0-9][0-9])$/;
        
        let format = /^([0-9][0-9][/\][0-9][0-9][-\][0-9]{0,14}[A-Z][0-9][0-9])$/; 
        if(data['registre'] && !format.test(data['registre'])){
            this.setState({error: "Le format de registre de commerce est incorrect"})
            return false;
        }

   
        if(data['client_name'] && (data['client_name'].indexOf("/") > -1)){
            this.setState({error: "Le format de nom du client est incorrect, merci d'enlever le / "})
            return false;
        }

      if (!this.state.form.station){
        this.props.setFlash({
          active: true,
          message: 'Veuillez remplir tous les champs',
          classes: "warning",
          style: {}
          });
        this.props.doneLoading();
        return 
      }


        this.props.isLoading();
        api.post('/api/client/new', jsonFromRef(e.target))
        .then(response => {
            if(response.data.success){
                this.props.setFlash({
                	active: true,
                	message: "Client ajouté avec succès",
                	classes: "success",
                	style: {}
                })
                this.props.history.push('/client');
            }else{
                this.setState({
                    error: response.data.error
                })
                console.log("error type: "+ response.data.error)
            }
                

            this.props.doneLoading();
        })
    }

  handleInputChangeF(e) {
    
    /*let s = this.state;
    const event = Object.assign({}, e);
    const that = this;

    s.form[event.target.name] = event.target.value;

    this.setState({...s}, function() {

      if(event.target.name === 'username') {

        that.checkUsername()

      } else if(event.target.name === 'email') {

        that.checkEmail()

      }

      });*/
    const { name, value, type, checked } = e.target;
    console.log(type);
    const updatedValue = type === "checkbox" ? checked : value;
    

    if (name === "retour" || name == "commission_collect"){
      console.log(name);
      console.log(checked);
      this.setState({name: checked});
          }
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: updatedValue
      }
    }), () => {
      // Additional logic to handle specific input name cases
      if (name === "username") {
        this.checkUsername();
      } else if (name === "email") {
        this.checkEmail();
      }
    });
	}
    checkUsername() {
		
		const that = this;
		const username = this.state.form.username;
		
		if(username.length < 4)
			return that.setState({validUsername: false})
		
		this.setState({
			processingUsername: true
		});

		api.post('/api/user/availability', { username: username})
		.then(function(response) {
			if(response.data.success) {
				that.setState({validUsername: true})
			} else {
				that.setState({validUsername: false})
			}

			that.setState({
				processingUsername: false
			});
		})
	}
    checkEmail() {
		
		const that = this;
		const email = this.state.form.email;
		
		if(!helpers.validateEmail(email))
			return that.setState({
				validEmail: false
			})
		
		if(email.indexOf(" ") !== -1)
			return that.setState({validEmail: false})
		
		this.setState({
			processingEmail: true
		});

		api.post('/api/user/availability', { email: email})
		.then(function(response) {
			if(response.data.success) {
				that.setState({validEmail: true})
			} else {
				that.setState({validEmail: false})
			}

			that.setState({
				processingEmail: false
			});
		})
	}
    updateOptionsState = (event) => {
        this.setState({typeClientSelected: event.target.value});
        //console.log("type client"+event.target.value)
    }

  updateFilter = (event, name) => {
    let value = "";

    value = event.target ? event.target.value : event.value;
    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: value
          }
        }
      );
    };


    render() {

        return (<>


            <div className={"container-header"}>
				Nouveau Client
			</div>


            <div className={"row"}>

            
                
                <div className={"col-md-6 np"}>

                    <div className={" ui-rect np"}>
                        
                        <form ref={(ref) => (this.clientForm = ref)} onSubmit={this.submitNewClient} >

                            <div className={"form-group"}>
                                <label>Type Client</label>
                                <select className={"form-control"} name= 'client_type' onChange={e => this.updateOptionsState(e)} value={this.state.typeClientSelected}>
                                    <option value='Client Corporate'>Client Corporate</option>
                                    <option value='Client Ecommerce'>Client Ecommerce</option>
                                    {/* <option value='Clients Grand Publiques'>Client Grand Publique</option> */}
                                </select>
                            </div>
                            <br/>
                            <fieldset className="border border-4 border-dark rounded">
                                
                                <legend className="w-auto text-left">Infromations compte utilisateur</legend>
                                <div className="m-2">
                                    {this.state.typeClientSelected !=='Clients Grand Publiques'  && <div className={"form-group availability-check"}>
                                    <label><b><Trans i18nKey="user_username" /> client *</b></label>
                                    <input required  className={"form-control" + (!this.state.validUsername ? ' invalid' : '')} type="text" name="username" onChange={this.handleInputChange} />
                                    
                                    <div className={"availability-check-indicator-wrap"}>
                                        <span className={"spinner-border availability-check-indicator" + (this.state.processingUsername ? " show" : "")}></span>
                                        
                                        <span 
                                        className={"validity-indicator"+ 
                                        (
                                            this.state.form.username.length > 0 && !this.state.processingUsername 
                                            ? this.state.validUsername ? " valid" :  " invalid" 
                                            : ""
                                        )}>
                                            <FontAwesomeIcon icon={this.state.form.username.length > 0 && !this.state.processingUsername && this.state.validUsername ? faCheck : faTimes} />
                                        </span>
                                    </div>
                                </div>}

                                {this.state.typeClientSelected !=='Clients Grand Publiques'  && <div className={"form-group availability-check"}>
                                    <label><b><Trans i18nKey="user_email" />*</b> </label>
                                    <input required className={"form-control" + (!this.state.validEmail ? ' invalid' : '')} type="text" name="email" onChange={this.handleInputChange}  />
                                    

                                    <div className={"availability-check-indicator-wrap"}>
                                        <span className={"spinner-border availability-check-indicator" + (this.state.processingEmail ? " show" : "")}></span>
                                        
                                        <span 
                                            className={"validity-indicator"+ 
                                            (
                                                this.state.form.email.length > 0 && !this.state.processingEmail 
                                                ? this.state.validEmail ? " valid" :  " invalid" 
                                                : ""
                                            )}>
                                                <FontAwesomeIcon icon={this.state.form.email.length > 0 && !this.state.processingEmail && this.state.validEmail ? faCheck : faTimes} />
                                        </span>

                                    </div>

                                </div>}

                                {this.state.typeClientSelected !=='Clients Grand Publiques'  && <div className={"form-group"}>
                                    <label><b><Trans i18nKey="user_password" />*</b> </label>
                                    <input required className="form-control" type="password" name="password" onChange={this.handleInputChange} />
                                </div>}
                                </div>
                                

                            </fieldset>
                            <br/>
                            <br/>

                            <fieldset className="border border-4 border-dark rounded">
                            <legend className="w-auto text-left"> Infromations supplémentaire</legend>
                                <div className="m-2">
                                    {this.state.typeClientSelected !=='Clients Grand Publiques'  && <div className={"form-group"}>
                                        <div className="row">
                                             <div className="col-6"><label>Registre de commerce</label> </div>
                                            <div className="col-6">Exemple Format register de commerce:  <span class="text-success">00/00-00000000X00</span></div>
                                        </div>
                                       
                                        <input name="registre" type={"text"} className={"form-control"} />
                                    </div> }

                                    {this.state.typeClientSelected !=='Clients Grand Publiques'  && <div className={"form-group"}>
                                        <label>NIF</label>
                                        <input name="NIF" type={"text"} className={"form-control"}  />
                                    </div> }

                                    {this.state.typeClientSelected !=='Clients Grand Publiques'  && <div className={"form-group"}>
                                        <label>NIS</label>
                                        <input name="NIS" type={"text"} className={"form-control"} />
                                    </div> }

                                    <div className={"form-group"}>
                                        <label><b>Raison social*</b></label>
                                        <input name="client_name" type={"text"} className={"form-control"} />
                                    </div>

                                    <div className={"form-group"}>
                                        <label><b>Raison commerciale*</b></label>
                                        <input name="client_raison_commercial" type={"text"} className={"form-control"} />
                                    </div>

                                    <div className={"form-group"}>
                                        <label><b>Address *</b></label>
                                        <input name="client_address" type={"text"} className={"form-control"} />
                                    </div>

                                    <div className={"form-group"}>
                                        <label><b>Téléphone *</b></label>
                                        <input name="client_phone" type={"text"} className={"form-control"} />
                                    </div>
                                </div>
                                

                            </fieldset>
                            
                            <br/>

                            <fieldset className="border border-4 border-dark rounded">
                            <legend className="w-auto text-left"> Infromations Facturation</legend>
                                <div className="m-2">
                                    <label><b>Type de facturation *</b></label>
                                    <Select
                                        classNamePrefix={"custom-select"}
                                        className={"custom-select-c "}
                                        options={this.state.typesFacture}
                                        placeholder={"Type Facturation"}
                                        name="facturation_type"
                                    />
                                  </div>

                                  <div className={"c-form-row form-group m-2"}>
                                    <label><b>Station *</b></label>
                                    <Select
                                      classNamePrefix={"custom-select"}
                                      className={"custom-select-c "}
                                      options={this.state.stations}
                                      placeholder={"Station"}
                                      name="station"
                                      onChange={e => this.updateFilter(e, 'station')}
                                      required={true}
                                    />
                                  </div>


                              </fieldset>

                              <br/>
                               <fieldset className="border border-4 border-dark rounded">
                                <legend className="w-auto text-left">Extra</legend>
                                <div className="m-2">
                                  <div className="form-group">
                                    <div className="form-check">
                                      <label style={{ marginTop: '5px' }}>
                                        <input
                                          //defaultValue="retour"
                                          //value="retour"
                                          checked={this.state.retour}
                                          onChange={this.handleInputChange}
                                          type="checkbox"
                                          name="retour"
                                          className="form-check-input"
                                        />
                                        <b> pas de retour</b>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <div className="form-check">
                                      <label style={{ marginTop: '5px' }}>
                                        <input
                                          // {/* defaultValue="commission_collect" */}
                                          //{/* value="commission_collect" */}
                                          checked={this.state.commission_collect} 
                                          onChange={this.handleInputChange}
                                          type="checkbox"
                                          name="commission_collect"
                                          className="form-check-input"
                                        />
                                        <b> pas de commission collect</b>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </fieldset>
                              <br/> 


                            <div className={"form-group"}>
                            {Boolean(this.state.error) && <span className={"alert alert-warning d-block"}>{this.state.error}</span>}
                                <button className={"btn btn-success"}>Ajouter</button>
                            </div>

                        </form>
                    </div>

                </div>

                <div className={"col-md-6"} style={{visibility: "hidden"}}>
                    <div className={" ui-rect"}>
                       <div className={"map-container "} style={{height: "400px", borderColor: '#ccc', borderWidth: "1px", borderRadius: "2px", borderStyle: "solid"}} id="here-maps"></div>
                    </div>
                   
                </div>
            </div>


        </>)
    }
}

const mapDispatchToProps = (dispatch) => 
	({
		isLoading: e => dispatch(startLoading(e)),
		doneLoading: e => dispatch(doneLoading()),
		setAuth: e => dispatch(setAuthAction(e)),
        setFlash: (f) => dispatch(setFlash(f)),
        closeFlash: (f) => dispatch(closeFlash(f))
    })
    
export default connect(null, mapDispatchToProps)(ClientNew);
