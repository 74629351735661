import React, { Component } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import {connect} from "react-redux"
import GuestTopMenu from "./Menus/GuestTopMenu";
import DriverTopMenu from "./Menus/DriverTopMenu";
import AgentTopMenu from "./Menus/AgentTopMenu";
import ManagerTopMenu from "./Menus/ManagerTopMenu";
import SocketContext from '../Utils/socket-context';
import {readNotifications, updateNotifications, setAuthAction} from "./../Redux/actions/index";
import api from '../Utils/Api';
import CommonTopMenu from './Menus/CommonTopMenu';

const mapStateToProps = state => {
	return {
		auth: state.auth,
		user: state.user,
		notifications: state.notifications
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setAuth: e => dispatch(setAuthAction(e)),
		logOut: e => dispatch(setAuthAction(e)),
		updateNotifications: e => dispatch(updateNotifications(e)),
		readNotifications: e => dispatch(readNotifications(e))
	}
}
	
const axios = require("axios");


class Header extends Component {

	constructor(props) {
		super(props);

		this.state = {
			updatedNotification: false
		}

		this.setReadNotifications = _.debounce(this.setReadNotifications.bind(this), 1000);
	}

	updateSession = () => {
		console.log('updating ')
		const that = this;
		api.get('/api/user/refresh')
		.then(function(response) {
			if(response.data.success) {
				that.props.setAuth({...response.data, stay: true});
			}
		})
	}


	setReadNotifications() {
		if(!this.state.updatedNotification && this.props.notifications.length) {

			const that = this;

			api.get('/api/notification/update?limit='+btoa(this.props.notifications[this.props.notifications.length-1].datetime)+'&token='+this.props.user.token)
			.then(function(response) {
				//that.setState({updatedNotification: true})
				that.props.readNotifications(1);
				return 1;
			});
		}
	}

	componentDidMount() {
		const that = this;

		if(this.props.socket) {
			this.props.socket.on('test', function (data) {
				console.log('event: ', data)
				if(data.type && data.type === 'user_session_update')
					that.updateSession(); 
			});
		}

	}

	componentDidUpdate(prev) {
		

		const that = this;

		if(this.props.socket && !prev.socket) {
			this.props.socket.on('test', function (data) {
				console.log('event: ', data)
				if(data.type && data.type === 'user_session_update')
				that.updateSession(); 
			});
		}

	}

	

	componentWillUnmount() {

		if(this.props.socket) {
			console.log('unbind socket')
			this.props.socket.off('test')
		}
	}



	render() {

		return(
			<div className="header-wrap">
				<div className="header-content fluid-container">

				{(this.props.auth) &&
					<CommonTopMenu
						logOut={this.props.logOut}
						user={this.props.user}
						setReadNotifications={this.setReadNotifications}
						{...this.props}
					/>
					
				}
					{/* {(this.props.auth) ? 
						
							(this.props.user.role === "agent")
							?	<AgentTopMenu logout={this.props.logOut} user={this.props.user} />
							: 	(this.props.user.role === "admin" || this.props.user.role === "manager") 
								? <ManagerTopMenu 
								setReadNotifications={this.setReadNotifications}
								{...this.props} 
								/>
								: <React.Fragment />
						
					: 
						(<GuestTopMenu />)
					} */}
					
				
					<div className={"clear"}></div>
				</div>
				
			</div>
		)
	}
}

const HeaderIO = props => (
	<SocketContext.Consumer>
		{socket => <Header {...props} socket={socket} />}
	</SocketContext.Consumer>
  )

export default connect(mapStateToProps, mapDispatchToProps)(HeaderIO);