import React from "react";
import ClientAddressRow from "./ClientAddressRow";
import api from "../../Utils/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { startLoading, doneLoading } from "../../Redux/actions";
import Select from "react-select";
import { closeFlash, setFlash } from './../../Redux/actions/index';
import Alert from 'react-bootstrap/Alert'

class ClientDetail extends React.Component {
  constructor(props) {
    super(props);
    
    this.handleInputChange = e => { 
      e.persist();
      // Handle checkbox inputs
      if (e.target.type === "checkbox") {
        this.setState(prevState => ({
          client: {
            ...prevState.client,
            [e.target.name]: e.target.checked,
          },
        }));
      } else {
        this.func(e); // Handle other input changes
      }
    }
 

    this.state = {
      client: {},
      addresses: [],
      addressesLoading: true,
      typesFacture: [],
      showAlert: false,
      warning: '',
      type:'', 
      client_type: 'Client Grand Publique',
      station: "",
			stationid: "",
			stations: []

    };
  }

  loadAddresses = () => {
    const clientid = this.props.match.params.id;

    api.get(`/api/client/${clientid}/address`).then(res => {
      console.log(res);
      this.setState({
        addressesLoading: false,
        addresses: res.data.data
      });
    });
  };

  loadClientDetails = () => {
    const clientid = this.props.match.params.id;

    api.get(`/api/client/${clientid}`).then(res => {
      console.log(res);
      this.setState({
        addressesLoading: false,
        client: res.data.data
      });
    });
  };


  saveClient = () => {

  }


  submitClient = e => {
    e.preventDefault();

    const formData = new FormData(e.target);

    let data = {
      clientid: this.props.match.params.id
    };

    this.props.isLoading({
      message: "Sauvegarde en cours",
      animate: true
    });

    for (var [key, value] of formData.entries()) data[key] = value;

    if(data['client_name'] && (data['client_name'].indexOf("/") > -1)){
      this.setState({
        showAlert: true,
        warning: 'Le format de nom du client est incorrect, merci d\'enlever le /',
        type:'warning'
      })
      this.props.doneLoading();
      return false;
    }

    api.post('/api/client/save', data)
    .then(res => {
      if(res.data.success){
        this.setState({
          showAlert: true,
          warning: 'Client Modifié avec succès',
          type:'success'
        })
        // this.props.setFlash({
        //   active: true,
        //   message: "Client Modifié avec succès",
        //   classes: "success",
        //   style: {}
        // })
      }else{
        this.setState({
          showAlert: true,
          warning: res.data.error,
          type:'danger'
        })
        // this.props.setFlash({
        //   active: true,
        //   message: res.data.error,
        //   classes: "danger",
        //   style: {}
        // })
      }
      this.props.doneLoading();
    })


  }

  componentDidMount() {
    this.loadClientDetails();
    this.loadAddresses();
    let that = this 
        api.get('/api/form/typefacturation').then(result => {
            if(result.data.success){
                that.setState({
                    typesFacture:result.data.types
                })
            }
        })

    api.get('/api/form/station').then(result => {
      if(result.data.success){
        that.setState({
          stations:result.data.stations
        })
      }
    })

  }

  addNewAddres = () => {
    const { addresses } = this.state;

    addresses.reverse();
    const obj = {
      open: true,
      address_label: "",
      address_address: "",
      address_id: "",
      address_contact_name: "",
      address_contact_phone: "",
      address_wilaya: "",
      address_commune: ""
    };

    addresses.push({ ...obj });
    addresses.reverse();

    console.log(addresses);

    this.setState({ addresses: addresses });
  };

  updateAddressField = (idx, field, event) => {
    const addresses = [...this.state.addresses];

    // const addr = {...this.state.addresses[idx]}

    if (field !== "mustSave") addresses[idx].mustSave = true;

    addresses[idx][field] = event.target
      ? event.target.value
      : event.value
      ? event.value
      : event;

    this.setState({ addresses });
  };

  saveAddress = idx => {
    this.updateAddressField(idx, "isSaving", true);

    const address = { ...this.state.addresses[idx] };

    const clientid = this.props.match.params.id;
    api
      .post(`/api/client/${clientid}/address/save`, { address: address })
      .then(res => {
        console.log(res.data);
        this.updateAddressField(idx, "isSaving", false);
        this.updateAddressField(idx, "mustSave", false);
      })
      .catch(err => {
        this.updateAddressField(idx, "isSaving", false);
      });
  };

  removeAddress = idx => {
    const { addresses } = this.state;

    addresses.splice(idx, 1);
    this.setState({ addresses });
  };

  deleteAddress = idx => {
    const { addresses } = this.state;

    const aid = addresses[idx].address_id;

    if (!aid) {
      this.removeAddress(idx);
      return;
    } else {
      this.setState({
        addressesLoading: true
      });

      api.delete(`/api/address/${aid}`).then(response => {
        this.setState({
          addressesLoading: true
        });

        this.removeAddress(idx);
      });
    }
  };

  changeType = (event) => {
    this.setState({
      client_type: event.target ? event.target.value : event.value
    })
  }

	handleSelect = (event, name) => {
		let value = event.target ? event.target.value : event.value;
		let label = event.target ? event.target.value : event.label;
    //let that = this
    console.log(event);
    console.log(name);

		this.setState({
				...this.state,
				[name]: label,
				stationid: value
		})
		console.log('info' + JSON.stringify(this.state.info))
	}


  render() {
    const clientType = [
      {label: 'Client Corporate', value: 'Client Corporate'},
      {label: 'Client Ecommerce', value: 'Client Ecommerce'}
    ]
    this.state.client.client_type && console.log('client type => '+ this.state.client.client_type.label)
    return (
      <>
        <div className={"container-header"}>Client details</div>

        <div className={"row"}>
          <div className={"col-md-6 np"}>
            <div className={" ui-rect np"}>
              <form onSubmit={this.submitClient}>
                
                {this.state.client.client_type && <div className={"form-group"}>
                  <label>Type Client</label>
                  {/* <select className={"form-control"} name= 'client_type' onChange={e => this.updateOptionsState(e)} value={this.state.typeClientSelected}>
                      <option value='Client Corporate'>Client Corporate</option>
                      <option value='Client Ecommerce'>Client Ecommerce</option>
                  </select> */}
                  <Select
                          defaultValue={this.state.client.client_type}
                          classNamePrefix={"custom-select"}
                          className={"custom-select-c "}
                          options={clientType}
                          placeholder={"Type client"}
                          name="client_type"
                          onChange = {(e) => this.changeType(e)}
                        />

                      
                </div>}
                {(this.state.client.client_user || (!this.state.client.client_user && this.state.client.client_type && (this.state.client.client_type.label !== "Client Grand Publique" || this.state.client_type !== "Client Grand Publique")) ) &&  <fieldset className="border border-4 border-dark rounded">
                    <legend className="w-auto text-left"> Informations compte utilisateur</legend>
                    <div className="m-2">
                      { this.state.client.client_user && <div className={"form-group"}>
                        <label>Nom d'utilisateur</label>
                        <input
                          defaultValue={this.state.client.client_username}
                          name="client_username"
                          type={"text"}
                          className={"form-control"}
                          disabled
                        />
                      </div>}
                      { !this.state.client.client_user  && <div className={"form-group"}>
                        <label>Nom d'utilisateur</label>
                        <input
                          defaultValue={this.state.client.client_username}
                          name="client_username"
                          type={"text"}
                          className={"form-control"}
                          
                        />
                      </div>}

                      <div className={"form-group"}>
                        <label>Email</label>
                        <input
                          defaultValue={this.state.client.client_email}
                          name="client_email"
                          type="email"
                          className={"form-control"}
                        />
                      </div>
                      <div className={"form-group"}>
                        <label>Password</label>
                        <input
                          //defaultValue={this.state.client.client_username}
                          name="client_password"
                          type={"password"}
                          className={"form-control"}
                        />
                      </div>
                    </div>

                    
                </fieldset>}
                <br/>
                <fieldset className="border border-4 border-dark rounded">
                  <legend className="w-auto text-left"> Informations supplémentaire</legend>
                  <div className="m-2">
                    <div className={"form-group"}>
                      <label>Raison social</label>
                      <input
                        defaultValue={this.state.client.client_name}
                        name="client_name"
                        type={"text"}
                        className={"form-control"}
                      />
                    </div>

                    <div className={"form-group"}>
                      <label>Raison commerciale</label>
                      <input
                        defaultValue={this.state.client.client_raison_commercial}
                        name="client_raison_commercial"
                        type={"text"}
                        className={"form-control"}
                      />

                      <label>Station</label>
                      <Select
                        classNamePrefix={"custom-select"}
                        className={"custom-select-c "}
                        options={this.state.stations}
                        value = {
                          this.state.stations.filter(option => 
                            option.label === this.state.station)
                          }
                          placeholder={"Station"}
                          name="station"
                          onChange={e => this.handleSelect(e, 'station')}
                          required={true}
                        />

                    </div>

                    <div className={"form-group"}>
                      <label>Contact principal</label>
                      <input
                        defaultValue={this.state.client.client_contact}
                        name="client_contact"
                        type={"text"}
                        className={"form-control"}
                      />
                    </div>

                    <div className={"form-group"}>
                      <label>Téléphone</label>
                      <input
                        defaultValue={this.state.client.client_phone}
                        name="client_phone"
                        type={"text"}
                        className={"form-control"}
                      />
                    </div>

                    <div className={"form-group"}>
                      <label>Addresse </label>
                      <input
                        defaultValue={this.state.client.client_adress}
                        name="client_adress"
                        type={"text"}
                        className={"form-control"}
                      />
                    </div>

                    <div className={"form-group"}>
                      <label>Regitre de commerce</label>
                      <input
                        defaultValue={this.state.client.client_register}
                        name="register"
                        type={"text"}
                        className={"form-control"}
                      />
                    </div>

                    <div className={"form-group"}>
                      <label>NIF</label>
                      <input
                        defaultValue={this.state.client.client_nif}
                        name="nif"
                        type={"text"}
                        className={"form-control"}
                      />
                    </div>

                    <div className={"form-group"}>
                      <label>NIS</label>
                      <input
                        defaultValue={this.state.client.client_nis}
                        name="nis"
                        type={"text"}
                        className={"form-control"}
                      />
                    </div>
                  </div>
                </fieldset>
                <br/>
                {this.state.client.client_user &&  <fieldset className="border border-4 border-dark rounded">
                   <legend className="w-auto text-left">Informations compte utilisateur</legend>
                   <div className="m-2">
                      <label>Type de facturation</label>
                      <Select
                          defaultValue={this.state.client.client_facturation}
                          classNamePrefix={"custom-select"}
                          className={"custom-select-c "}
                          options={this.state.typesFacture}
                          placeholder={"Type Facturation"}
                          name="facturation_type"
                      />
                  </div>
              </fieldset>}

               <br/>
               <fieldset className="border border-4 border-dark rounded">
                <legend className="w-auto text-left">Extra</legend>
                <div className="m-2">
                  <div className="form-group">
                    <div className="form-check">
                      <label style={{ marginTop: '5px' }}>
                        <input
                          //defaultValue="retour"
                          //value="retour"
                          checked={this.state.client.retour}
                          onChange={this.handleInputChange}
                          //onChange={()=>this.setState({client.retour = !client.retour})}
                          //onChange =  {this.setState({ client.retour : !client.retour })}
                          type="checkbox"
                          name="retour"
                          className="form-check-input"
                          //defaultValue={this.state.client.retour}
                        />
                        <b> pas de retour</b>
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-check">
                      <label style={{ marginTop: '5px' }}>
                        <input
                          // {/* defaultValue="commission_collect" */}
                          //{/* value="commission_collect" */}
                          checked={this.state.client.commission_collect} 
                          //onChange={()=>this.setState({client.commission_collect = !client.commission_collect})}
                          onChange={this.handleInputChange}
                          //onChange =  {this.setState({ client.commission_collect : !client.commission_collect })}
                          type="checkbox"
                          name="commission_collect"
                          className="form-check-input"
                          //defaultValue={this.state.client.commission_collect}
                        />
                        <b> pas de commission collect</b>
                      </label>
                    </div>
                  </div>
                </div>
              </fieldset>
              <br/> 

                {this.state.showAlert && <Alert onClose={() => this.setState({showAlert:false})} dismissible variant={this.state.type}>
							    {this.state.warning}
						    </Alert>}

                <br/>

                <div className={"form-group"}>
                  <button className={"btn btn-success"}>Sauvegarder</button>
                </div>
              </form>
            </div>
          </div>

          <div className={"col-md-6"}>
            <div className={" ui-rect"}>
              <div className={"rect-header"}>
                <h4>
                  Adresses
                  {this.state.addressesLoading && (
                    <FontAwesomeIcon
                      style={{ marginLeft: 15 }}
                      spin={true}
                      icon={faSpinner}
                    />
                  )}
                </h4>
                <button
                  onClick={this.addNewAddres}
                  type="button"
                  className={"cbtn"}
                >
                  Ajouter
                </button>
              </div>

              <form>
                {this.state.addresses.map((address, idx) => (
                  <ClientAddressRow
                    deleteAddress={this.deleteAddress}
                    saveAddress={this.saveAddress}
                    updateAddressField={this.updateAddressField}
                    address={address}
                    key={address.address_id + "_" + idx}
                    idx={idx}
                  />
                ))}
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatch = dispatch => {
  return {
    isLoading: m => dispatch(startLoading(m)),
    doneLoading: m => dispatch(doneLoading()),
    setFlash: (f) => dispatch(setFlash(f)),
    closeFlash: (f) => dispatch(closeFlash(f))
  };
};
export default connect(null, mapDispatch)(ClientDetail);
